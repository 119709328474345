import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import layoutContext from "./layoutContext";
import { Loader, LoaderSvg, OutVector, Vector } from "../assets/svg/AllSvg";
import { Logo } from "../assets/image";
import { useDispatch, useSelector } from "react-redux";
import SidebarDropDown from "../commons/SidebarDropDown";
import AddCompany from "../pages/modal/AddCompany";
import { getCompany } from "../redux/companies/slice";
import InfiniteScroll from "react-infinite-scroll-component";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [addEditRolePopup, setAddCompanyPopup] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Selector
  const { GetCompany } = useSelector((store) => ({
    // Company Data
    GetCompany: store?.companiesData?.getCompanyStatus?.data?.data,
  }));

  // Get APIs
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getCompany(page));
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const companyID = !selectedCompany.companyId
    ? GetCompany?.data[0]?.id
    : selectedCompany.companyId;

  sessionStorage.setItem("companyID", companyID);

  const Modal = ({ isOpen, onClose }) => {
    const modalRef = useRef(null);

    const handleCompanySelect = (company) => {
      if (company) {
        localStorage.setItem("selectedCompany", JSON.stringify(company));
        setSelectedCompany(company);
        onClose();
      } else {
        localStorage.removeItem("selectedCompany");
        setSelectedCompany(company);
      }
      navigate("/", { state: { selectedCompany: company } });
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onClose();
        }
      };

      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, onClose]);

    const fetchMoreData = () => {
      if (!loading && GetCompany?.current_page < GetCompany?.last_page) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    return (
      <>
        {isOpen && (
          <div className="fixed inset-0 flex items-start justify-start mt-16 left-52 ml-1">
            <div
              ref={modalRef}
              className="bg-white px-4 rounded-lg shadow-lg overflow-auto pb-4 h-60 overflow-x-hidden justify-between flex flex-col"
            >
              <InfiniteScroll
                className="hide-scroll"
                dataLength={GetCompany?.data?.length ?? 0}
                next={fetchMoreData}
                loader={
                  loading && (
                    <div className="text-center">
                      <LoaderSvg />
                    </div>
                  )
                }
                hasMore={GetCompany?.current_page < GetCompany?.last_page}
                scrollableTarget="scrollableDiv"
              >
                <ul className="divide-y divide-gray-200">
                  {GetCompany?.data?.length > 0 ? (
                    GetCompany?.data?.map((company, index) => {
                      return (
                        <li key={index} className="py-2">
                          <button
                            className="focus:outline-none text-start"
                            onClick={() =>
                              handleCompanySelect({
                                company,
                                companyId: company.id,
                                companyName: company.name,
                                uen: company.uen,
                              })
                            }
                          >
                            <p className="font-poppins font-normal text-sm text-black">
                              {company.name}
                            </p>
                            <p className="font-poppins font-normal text-xs text-black">
                              {company.uen != null ? "UEN: " + company.uen : ""}
                            </p>
                          </button>
                        </li>
                      );
                    })
                  ) : (
                    <li className="py-2">
                      <p className="text-sm text-black">
                        No companies available
                      </p>
                    </li>
                  )}
                </ul>
              </InfiniteScroll>
              <button
                className="mt-4 bg-gray-100 hover:bg-gray-200 text-black p-1 items-center flex rounded-md w-44 justify-center"
                onClick={() => {
                  setAddCompanyPopup(true);
                  onClose();
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 7V3H2V21H16V19H12V17H14V15H12V13H14V11H12V9H20V15H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM24 19V21H22V23H20V21H18V19H20V17H22V19H24ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </svg>
                <p className="ml-1 font-poppins font-normal text-xs">
                  Add Company
                </p>
              </button>
            </div>
          </div>
        )}
        {addEditRolePopup && (
          <AddCompany setAddCompanyPopup={setAddCompanyPopup} />
        )}
      </>
    );
  };

  // hooks
  const { isAsideOpen } = useContext(layoutContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { getProfileData } = useSelector((store) => ({
    getProfileData: store?.authData?.getProfileDataStatus?.data,
  }));

  // Log Out Clear
  const logOutClear = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <aside
        className={`${
          isAsideOpen ? "w-[16rem]" : "md:w-[5rem] w-0"
        } fixed top-0 z-[99] md:relative flex flex-col justify-between md:overflow-visible overflow-hidden transition-all border-r h-[calc(100vh-4rem)]`}
      >
        <div className="h-full">
          <div className="flex items-center p-4 border-b border-gray-200 gap-x-2 bg-primaryShadesLight justify-between">
            <div className="flex gap-1">
              <svg
                width="33"
                height="24"
                viewBox="0 0 33 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 5.7V4H16L15 2.51L16 1H11V5.7L2 12V22H9V17L12.03 15L15 17V22H22V12L13 5.7Z"
                  fill="#065699"
                />
              </svg>
              {selectedCompany ? (
                <div className="mr-2">
                  <p className="font-poppins font-normal text-sm text-secondary -tracking-wide">
                    {selectedCompany
                      ? selectedCompany.companyName
                      : "No company"}
                  </p>
                  <p className="font-poppins font-normal text-xs text-theme">
                    {selectedCompany && selectedCompany.uen != null
                      ? `UEN: ${selectedCompany.uen}`
                      : ""}
                  </p>
                </div>
              ) : GetCompany?.data?.length ? (
                <div className="mr-2">
                  <p className="font-poppins font-normal text-sm text-secondary -tracking-wide">
                    {GetCompany.data[0]?.name}
                  </p>
                  <p className="font-poppins font-normal text-xs text-theme">
                    {`${
                      GetCompany.data[0]?.uen
                        ? "UEN: " + GetCompany.data[0].uen
                        : ""
                    }`}
                  </p>
                </div>
              ) : (
                <p>No company</p>
              )}
            </div>
            <button onClick={openModal}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} />
          </div>

          <ul
            className={` h-[calc(100vh-12.9rem)] ${
              isAsideOpen ? "overflow-y-auto" : "overflow-visible"
            }  `}
          >
            {getProfileData?.permission_list?.map((menu, index) => (
              <>
                {menu?.subMenu?.length ? (
                  <SidebarDropDown isAsideOpen={isAsideOpen} menu={menu} />
                ) : (
                  <li key={index} className={`group aside-links tooltipBlock`}>
                    <button
                      className={`flex items-center relative z-[99999] ${
                        !isAsideOpen
                          ? "px-2 hover:translate-x-0 hover:bg-lightSecondaryShades my-1 justify-center"
                          : "hover:translate-x-1 px-4 "
                      }  py-2 gap-x-4 h-auto  hover:text-black w-full rounded-lg transition duration-200  ${
                        location.pathname ===
                          `/${menu.path === "dashboard" ? "" : menu?.path}` &&
                        `bg-lightSecondaryShades`
                      }`}
                      onClick={() => {
                        const targetPath =
                          menu?.path === "dashboard" ? "/" : menu?.path;
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(selectedCompany)
                        );
                        navigate(targetPath, { state: { selectedCompany } });
                      }}
                    >
                      <div className="max-w-5 max-h-5 w-full">
                        <img
                          src={menu?.icon_path ?? Logo}
                          className="w-full h-full object-contain"
                          alt="icon"
                        />
                      </div>
                      <p
                        className={`origin-left font-medium duration-250 flex items-center text-left  ${
                          !isAsideOpen && "hidden"
                        }`}
                      >
                        {menu.title}
                      </p>
                      {!isAsideOpen && (
                        <div
                          id="tooltip"
                          className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
                        >
                          <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                          <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                            {menu.title}
                          </div>
                        </div>
                      )}
                    </button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
        {/* Log Out */}
        <div
          className="p-4 flex items-center border-t hover:bg-lightSecondaryShades"
          onClick={() => logOutClear()}
        >
          <button
            className={`flex items-center relative z-[99999] ${
              !isAsideOpen
                ? "px-2 hover:translate-x-0 py-1 justify-center"
                : "hover:translate-x-1 px-4 "
            } gap-x-4 h-auto hover:text-black w-full rounded-lg transition duration-200`}
          >
            <OutVector />
            <p
              className={`origin-left font-medium duration-250 flex  items-center  ${
                !isAsideOpen && "hidden"
              }   `}
            >
              Log Out
            </p>
            {!isAsideOpen && (
              <div
                id="tooltip"
                className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
              >
                <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                  <OutVector />
                </div>
              </div>
            )}
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
