import React, { useState } from "react";

import ReactSelect from "react-select";
// import "react-quill/dist/quill.snow.css";
import PdfDocument from "../../commons/PdfDocument";
import { Close, UserIcon } from "../../assets/svg/AllSvg";
import TicketModal from "../modal/AddTickets";
import TextEditor from "./TextEditor";

const ReplyTicket = ({ setReplyTicketModel, replyTicketModel }) => {
  const [confirmCreateTicket, setConfirmCreateTicket] = useState(false);
  const [createTicketData, setCreateTicketData] = useState({});

  const handleConfirmCreateTicket = () => {
    console.log(createTicketData);
    setConfirmCreateTicket(false);
  };

  return (
    <div
      className="model"
      onClick={() => {
        setReplyTicketModel(false);
      }}
    >
      <div
        className="bg-white drop-shadow-lg rounded-lg w-full max-w-3xl max-h-[700px] overflow-y-auto min-h-36 py-5 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Close
          className="cursor-pointer absolute top-5 right-5"
          onClick={() => {
            setReplyTicketModel(false);
          }}
        />
        <div className="space-y-4">
          <div className="text-dark_purple text-sm space-y-1">
            <p className="w-full font-medium text-lg">Customer Name</p>
            <p>Customer Email: customer@email.com</p>
            <p>Company: -</p>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <span className="rounded-t-md bg-lightThemeSecondary text-white px-4 py-1">
                New
              </span>
              <p className="text-theme">Ticket# 2023-CS123</p>
            </div>
            <p className="text-light_grey">Posted on 24/08/2023 | 13:00</p>
          </div>
          <p className="text-secondary">
            Ticket Tile: Title description here ...
          </p>
          <div className="max-h-60 space-y-3 overflow-y-auto">
            {[1, 2, 3, 4].map((e, i) => {
              return (
                <div
                  className={`${
                    i % 2 === 0 ? "bg-greenLight" : "bg-blueBg"
                  } text-secondary rounded-xl space-y-2 p-4`}
                >
                  <div className="flex items-center justify-between">
                    <p className="font-ebGaramond text-xl">User</p>
                    <p className="text-light_grey text-sm">
                      27/08/2023 | 13:00
                    </p>
                  </div>
                  <div>
                    <p className="text-sm mb-1">Message:</p>
                    <p className="text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
                    </p>
                  </div>
                  <div className="grid grid-cols-3">
                    <PdfDocument
                      documentName={"name.pdf"}
                      cardClasses={"bg-white"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="border-t border-secondary_grey space-y-3 pt-2">
            <TextEditor replyTicketModel={replyTicketModel} />
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2 w-full">
                <div className="max-w-8 w-full h-8 flex justify-center items-center bg-grey rounded-full">
                  <UserIcon pathClass="fill-white" fillOpacity={1} />
                </div>
                <p className="text-theme border-b border-theme">Ryan</p>
              </div>
              <button
                className="btn-theme uppercase whitespace-nowrap"
                onClick={() => {
                  setConfirmCreateTicket(true);
                }}
              >
                Submit Reply
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Confirm Create Ticket */}
      {confirmCreateTicket && (
        <div
          className="model"
          onClick={(e) => {
            setConfirmCreateTicket(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-md min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-[34px] font-ebGaramond mb-3">
              Confirm Create Ticket?
            </h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setConfirmCreateTicket(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  handleConfirmCreateTicket();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReplyTicket;
