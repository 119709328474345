import React from "react";
import { BackButton } from "../components/micro";
import { Logo, man } from "../assets/image";
import { ApprovedContractApplication } from "../assets/svg/AllSvg";
import CheckOutUnsuccessfully from "./CheckOutUnsuccessfully";

const CheckOut = () => {
  return (
    <div className="grid grid-cols-3 gap-5">
      <div className="col-span-2 box bg-white !px-6 !py-5 !rounded-xl">
        <BackButton className={"mb-10"} />
        <div className="flex items-center border-b-2 border-secondary_grey space-x-4 pb-1">
          <img src={man} alt="man" />
          <div className="font-ebGaramond">
            <p className="text-xl">You are all set</p>
            <p className="text-3xl">To Checkout Now!</p>
          </div>
        </div>
        <div className="">
          <p>Payment Method</p>
        </div>
      </div>
      <div className="box bg-light_info_shades !rounded-xl">
        <div className="w-14 h-14 mx-auto my-5">
          <img src={Logo} alt="logo" className="w-full h-full object-cover" />
        </div>
        <div className="flex items-center justify-between bg-bgPrimary rounded-lg px-3 py-2 mb-5">
          <p className="font-semibold">Total Payable:</p>
          <p className="text-xl">$490.50</p>
        </div>
        <div>
          <div className="flex items-center justify-between bg-light_primary_shades border-b border-secondary_grey rounded-t-lg px-3 py-2">
            <p>Breakdown</p>
            <ApprovedContractApplication />
          </div>
          <div className="space-y-3 border-b border-secondary_grey py-3">
            <div className="flex justify-between">
              <p>1 new local director</p>
              <div className="text-right">
                <p className="text-sm text-gray-400">indication</p>
                <p className="text-xl">$150</p>
              </div>
            </div>
            <div className="flex justify-between">
              <p>1 new local director</p>
              <div className="text-right">
                <p className="text-sm text-gray-400">indication</p>
                <p className="text-xl">$150</p>
              </div>
            </div>
            <div className="flex justify-between">
              <p>1 new local director</p>
              <div className="text-right">
                <p className="text-sm text-gray-400">indication</p>
                <p className="text-xl">$150</p>
              </div>
            </div>
          </div>
          <div className="border-b border-secondary_grey py-3">
            <div className="flex justify-between">
              <p>Sub Total:</p>
              <p className="text-xl">$450</p>
            </div>
            <div className="flex justify-between">
              <p>GST 9%:</p>
              <p className="text-xl">$40.50</p>
            </div>
          </div>
          <div className="flex items-center justify-between bg-light_primary_shades rounded-b-lg px-3 py-2 mb-5">
            <p>Total</p>
            <p className="text-xl">$490.50</p>
          </div>
        </div>
        <button className="btn">View less</button>
      </div>
    </div>
  );
};

export default CheckOut;
