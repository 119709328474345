import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash } from "iconsax-react";
import { newPassword } from "../../redux/auth/slice";
import { useDispatch } from "react-redux";
import { Logo, Reset } from "../../assets/image";
import "./Auth.css";
import { InputWithLabel } from "../../components/micro";

const NewPassword = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, token } = useParams();

  return (
    <>
      <div className="main">
        <div className="main-logo">
          <img src={Logo} alt="logo" className="w-full h-full object-cover" />
        </div>
        <div className="center-h">
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
              <div className="relative top-5 left-1/4 w-fit h-fit">
                <img
                  src={Reset}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="main-view ">
                <div className={"mb-8"}>
                  <h1 className="sm:text-2xl text-2xl font-ebGaramond font-normal">
                    New Password!
                  </h1>
                  <p className="text-sm opacity-65">Key in your new password</p>
                </div>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string()
                      .required("Please enter your new password")
                      .min(6, "Password must be at least 6 characters long"),
                    confirmPassword: Yup.string()
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      )
                      .required("Please confirm your new password"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    dispatch(
                      newPassword({ email: email, code: token, ...values })
                    ).then((res) => {
                      setLoading(false);
                      if (res.type === "newPassword/fulfilled") {
                        navigate("/password-successful");
                      }
                    });
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="space-y-5">
                        {/* New Password */}
                        <InputWithLabel
                          className={
                            errors.password && touched.password
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="password"
                          label="New Password"
                          type={passwordType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          errors={errors.password && touched.password}
                          errorsText={errors.password}
                          icon={
                            <p
                              className="p-2 absolute top-1.5 right-0 rounded bg-transparent z-20"
                              onClick={() => {
                                setPasswordType(
                                  passwordType === "password"
                                    ? "text"
                                    : "password"
                                );
                              }}
                            >
                              {passwordType === "password" ? (
                                <EyeSlash size="20" />
                              ) : (
                                <Eye size="20" />
                              )}
                            </p>
                          }
                        />
                        {/* Confirm New Password */}
                        <InputWithLabel
                          className={
                            errors.confirmPassword && touched.confirmPassword
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="confirmPassword"
                          label="Confirm New Password"
                          type={confirmPasswordType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          errors={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          errorsText={errors.confirmPassword}
                          icon={
                            <p
                              className="p-2 absolute top-1.5 right-0 rounded bg-transparent z-20"
                              onClick={() => {
                                setConfirmPasswordType(
                                  confirmPasswordType === "password"
                                    ? "text"
                                    : "password"
                                );
                              }}
                            >
                              {confirmPasswordType === "password" ? (
                                <EyeSlash size="20" />
                              ) : (
                                <Eye size="20" />
                              )}
                            </p>
                          }
                        />
                        <button
                          type="submit"
                          disabled={loading}
                          className="w-full block uppercase text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4"
                        >
                          {loading ? "Loading..." : "CONFIRM NEW PASSWORD"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
