import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DefaultLayout from "../layouts";
import Auth from "../pages/auth/Auth";
import Login from "../pages/auth/Login";
import Page404 from "../commons/Page404";
import Page500 from "../commons/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import PasswordSuccessful from "../pages/auth/PasswordSuccessful";
import Dashboard from "../pages/dashboard/Dashboard";
import NewCompany from "../pages/company/NewCompany";
import CompanyInfo from "../pages/company/CompanyInfo";
import CompanyAddress from "../pages/company/CompanyAddress";
import InviteCompany from "../pages/company/InviteCompany";
import Accounting from "../pages/company/Accounting";
import Payment from "../pages/company/Payment";
import PaymentLast from "../pages/company/PaymentLast";
import PaymentGateway from "../pages/company/PaymentGateway";
import CheckOutSuccess from "../pages/company/CheckOutSuccess";
import CheckOutUnSuccess from "../pages/company/CheckOutUnsuccess";
import NomineeDirector from "../pages/company/Nominee";
import MyCompany from "../pages/company/MyCompany";
import MyOrganization from "../pages/company/MyOrganization";
import ServiceRequest from "../pages/serviceRequest/ServiceRequest";
import LocalDirector from "../pages/serviceRequest/appointments/LocalDirector";
import Approver from "../pages/serviceRequest/appointments/Approver";
import CheckOut from "../commons/CheckOut";
import OpenBankAccount from "../pages/serviceRequest/appointments/OpenBankAccount";
import CloseBankAccount from "../pages/serviceRequest/appointments/CloseBankAccount";
import ChangeCompanyName from "../pages/serviceRequest/appointments/ChangeCompanyName";
import ChangeBusinessActivity from "../pages/serviceRequest/appointments/ChangeBusinessActivity";
import ChangeDirectorParticulars from "../pages/serviceRequest/appointments/ChangeDirectorParticulars";
import VerificationEmail from "../pages/auth/VerificationEmail";
import Profile from "../pages/userDetails/Profile";
import ChatList from "../pages/chat/Chat";
import ChangeCompanyAddress from "../pages/serviceRequest/appointments/ChangeCompanyAddress";
import ChangeShareholderParticulars from "../pages/serviceRequest/appointments/ChangeShareholderParticulars";
import ChangeFinancialYearEnd from "../pages/serviceRequest/appointments/ChangeFinancialYearEnd";
import AllotmentTransferNewShareholder from "../pages/serviceRequest/appointments/AllotmentTransferNewShareholder";
import DirectorSuccessful from "../pages/auth/DirectorSucccessful";
import Tickets from "../pages/tickets/indexs";
import AllTickets from "../pages/tickets/AllTickets";
import ApplyingForPasses from "../pages/serviceRequest/appointments/ApplyingForPasses";
import OnGoingService from "../pages/serviceRequest/OnGoingService";
import OnGoingRequest from "../pages/serviceRequest/appointments/OnGoingRequest";

const AppRoutes = () => {
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" />
      <Routes>
        {/* Auth */}
        <Route exact path="/sign-up" element={<Auth />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/verification-email" element={<VerificationEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password/:email/:token" element={<NewPassword />} />
        <Route path="/password-successful" element={<PasswordSuccessful />} />
        <Route path="/director-successful" element={<DirectorSuccessful />} />
        {/* DefaultLayout */}
        <Route path="/" element={<DefaultLayout />}>
          {/* commons */}
          <Route path="/check-out" element={<CheckOut />} />
          {/* Dashboard */}
          <Route index path="/" element={<Dashboard />} />
          {/* profile */}
          <Route path="/profile" element={<Profile />} />
          {/* Company */}
          <Route path="/new-company" element={<NewCompany />} />
          <Route path="/company-info" element={<CompanyInfo />} />
          <Route path="/company-address" element={<CompanyAddress />} />
          <Route path="/invite-company" element={<InviteCompany />} />
          <Route path="/accounting" element={<Accounting />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-last" element={<PaymentLast />} />
          <Route path="/payment-gateway" element={<PaymentGateway />} />
          <Route path="/success" element={<CheckOutSuccess />} />
          <Route path="/unSuccess" element={<CheckOutUnSuccess />} />
          <Route path="/nominee" element={<NomineeDirector />} />
          <Route path="/my-company-information" element={<MyCompany />} />
          <Route path="/my-organisation" element={<MyOrganization />} />
          {/* Service Request */}
          <Route path="/service-request" element={<ServiceRequest />} />
          <Route path="/local-director" element={<LocalDirector />} />
          <Route path="/open-bank-account" element={<OpenBankAccount />} />
          <Route path="/close-bank-account" element={<CloseBankAccount />} />
          <Route path="/change-company-name" element={<ChangeCompanyName />} />
          <Route
            path="/change-financial-year-end"
            element={<ChangeFinancialYearEnd />}
          />
          <Route
            path="/change-shareholder-particular"
            element={<ChangeShareholderParticulars />}
          />
          <Route
            path="/change-company-address"
            element={<ChangeCompanyAddress />}
          />
          <Route
            path="/change-business-activity"
            element={<ChangeBusinessActivity />}
          />
          <Route
            path="/change-director-particular"
            element={<ChangeDirectorParticulars />}
          />
          <Route
            path="/allotment-transfer-new-shareholder"
            element={<AllotmentTransferNewShareholder />}
          />
          <Route
            path="/applying-passes"
            element={<ApplyingForPasses />}
          />
          <Route path="/approver" element={<Approver />} />
          <Route index path="/approver" element={<Approver />} />
          <Route path="/on-going-service" element={<OnGoingService />} />
          <Route path="/on-going-request" element={<OnGoingRequest />} />
          {/* chat */}
          <Route index path="/chat-list" element={<ChatList />} />
          {/* Tickets */}
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/all-tickets" element={<AllTickets />} />
          {/* Page 404 */}
          <Route
            exact
            path="*"
            name="Page 404"
            status={404}
            element={<Page404 />}
          />
          {/* Page 500 */}
          <Route
            exact
            path="/500"
            name="Page 500"
            status={500}
            element={<Page500 />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
