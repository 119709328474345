import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import ReplyTicket from "./ReplyTicket";
import { MoreMenu, Next, Previous, User } from "../../assets/svg/AllSvg";
import { Link } from "react-router-dom";

const fetchData = (page, perPage) => {
  const total = 100;
  return {
    current_page: page,
    per_page: perPage,
    data: Array.from({ length: perPage }, (_, index) => ({
      id: index + 1 + (page - 1) * perPage,
      title: `Ticket #${index + 1 + (page - 1) * perPage}`,
    })),
    total,
  };
};

const ShowLimit = ({ paginationCurrentLimit, setPaginationCurrentLimit }) => (
  <div className="flex items-center">
    <label htmlFor="pagination" className="text-xs opacity-70 mr-2">
      Rows per page:
    </label>
    <div className="text-xs">
      <select
        name="pagination"
        id="pagination"
        value={paginationCurrentLimit.value}
        onChange={(e) => {
          setPaginationCurrentLimit({
            value: parseInt(e.target.value, 10),
            label: `${e.target.value} per page`,
          });
        }}
        className="px-1"
      >
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
      </select>
    </div>
  </div>
);

const Pagination = ({ total, current, setCurrentPage, perPage }) => {
  const totalPages = Math.ceil(total / perPage);
  if (totalPages < 1) return null;

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => setCurrentPage(current - 1)}
        disabled={current <= 1}
        aria-label="Previous page"
      >
        <Previous />
      </button>
      <button
        onClick={() => setCurrentPage(current + 1)}
        disabled={current >= totalPages}
        aria-label="Next page"
      >
        <Next />
      </button>
    </div>
  );
};

const AllTickets = ({ isOpen }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [paginationCurrentLimit, setPaginationCurrentLimit] = useState({
    value: 10,
    label: "10 per page",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(() => fetchData(1, 10));
  const [replyTicketModel, setReplyTicketModel] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const options = [
    { value: "Ryan1", label: "Ryan1" },
    { value: "Ryan2", label: "Ryan2" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    const fetchNewData = () => {
      setData(fetchData(currentPage, paginationCurrentLimit.value));
    };

    fetchNewData();
  }, [currentPage, paginationCurrentLimit.value]);

  return (
    <div>
      {/* New */}
      <div className="mt-4 border border-gray-300 p-4 rounded-lg">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            {/* status */}
            <span className="bg-lightTheme text-white text-xs font-normal me-2 px-2.5 py-0.5 rounded-t-lg">
              New
            </span>
            <p className="font-poppins font-normal text-sm">
              Ticket# 2023-CS123
            </p>
            <span className="bg-red text-white text-xs font-normal me-2 px-2.5 py-0.5 rounded-2xl">
              High Priority
            </span>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-poppins font-normal text-xs">
              Last Updated on 24/08/2023 | 13:00
            </p>
            <button>
              <MoreMenu />
            </button>
          </div>
        </div>
        <div className="mt-2">
          <p className="font-poppins font-normal text-sm text-themeBlue">
            Subject here
          </p>
          <p className="font-poppins font-normal text-xs text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit
            amet, consectetur adipiscing ...
          </p>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="gap-2 flex items-center">
            <User />
            <div>
              <ReactSelect
                className={"w-32 max-w-xs text-black p-0"}
                value={selectedOption}
                onChange={handleChange}
                options={options}
                placeholder="Ryan"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid #cdddeb`,
                    borderRadius: "0.3rem",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #cdddeb",
                    },
                  }),
                  fontSize: "12px",
                  placeholder: (base) => ({
                    ...base,
                    fontSize: "12px",
                    padding: "0px",
                  }),
                  input: (base) => ({
                    ...base,
                    fontSize: "12px",
                    padding: "0px",
                  }),
                }}
              />
            </div>
          </div>
          <button
            onClick={() => {
              setReplyTicketModel(true);
            }}
          >
            <Link
              to="#"
              className="font-normal text-themeBlue underline font-poppins text-sm"
            >
              Open Ticket
           </Link>
          </button>
        </div>
      </div>

      {/*  On-Going */}
      <div className="mt-4 border border-gray-300 p-4 rounded-lg">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            {/* status */}
            <span className="bg-lightTheme1 text-theme text-xs font-normal me-2 px-2.5 py-0.5 rounded-t-lg">
              On-Going
            </span>
            <p className="font-poppins font-normal text-sm">
              Ticket# 2023-CS123
            </p>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-poppins font-normal text-xs">
              Last Updated on 24/08/2023 | 13:00
            </p>
            <button>
              <MoreMenu />
            </button>
          </div>
        </div>
        <div className="mt-2">
          <p className="font-poppins font-normal text-sm text-themeBlue">
            Subject here
          </p>
          <p className="font-poppins font-normal text-xs text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit
            amet, consectetur adipiscing ...
          </p>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="gap-2 flex items-center">
            <div>
              <ReactSelect
                className={"w-36 max-w-xs text-black p-0"}
                value={selectedOption}
                onChange={handleChange}
                options={options}
                placeholder="Assign Staff"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid #cdddeb`,
                    borderRadius: "0.3rem",
                    boxShadow: "none",
                    padding: 0,
                    "&:hover": {
                      border: "1px solid #cdddeb",
                    },
                  }),
                  fontSize: "12px",
                  placeholder: (base) => ({
                    ...base,
                    fontSize: "12px",
                    padding: "0px",
                  }),
                  input: (base) => ({
                    ...base,
                    fontSize: "12px",
                    padding: "0px",
                  }),
                }}
              />
            </div>
          </div>
          <div>
            <Link
              to="#"
              className="font-normal text-themeBlue underline font-poppins text-sm"
            >
              Open Ticket
           </Link>
          </div>
        </div>
      </div>

      {/* Resolved */}
      <div className="mt-4 border border-gray-300 p-4 rounded-lg">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            {/* status */}
            <span className="bg-lightGreenRgb text-themeBlue text-xs font-normal me-2 px-2.5 py-0.5 rounded-t-lg">
              Resolved
            </span>
            <p className="font-poppins font-normal text-sm">
              Ticket# 2023-CS123
            </p>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-poppins font-normal text-xs">
              Last Updated on 24/08/2023 | 13:00
            </p>
            <button>
              <MoreMenu />
            </button>
          </div>
        </div>
        <div className="mt-2">
          <p className="font-poppins font-normal text-sm text-themeBlue">
            Subject here
          </p>
          <p className="font-poppins font-normal text-xs text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit
            amet, consectetur adipiscing ...
          </p>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="gap-2 flex items-center">
            <User />
            <div>
              <p className="font-normal text-gray-500 font-poppins text-sm">
                {" "}
                Body 1
              </p>
            </div>
          </div>
          <div>
            <Link
              to="#"
              className="font-normal text-themeBlue underline font-poppins text-sm"
            >
              Open Ticket
           </Link>
          </div>
        </div>
      </div>

      {/* Archived */}
      <div className="mt-4 border border-gray-300 p-4 rounded-lg">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            {/* status */}
            <span className="bg-lightGrey text-themeBlue text-xs font-normal me-2 px-2.5 py-0.5 rounded-t-lg">
              Archived
            </span>
            <p className="font-poppins font-normal text-sm">
              Ticket# 2023-CS123
            </p>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-poppins font-normal text-xs">
              Last Updated on 24/08/2023 | 13:00
            </p>
            <button>
              <MoreMenu />
            </button>
          </div>
        </div>
        <div className="mt-2">
          <p className="font-poppins font-normal text-sm text-themeBlue">
            Subject here
          </p>
          <p className="font-poppins font-normal text-xs text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit
            amet, consectetur adipiscing ...
          </p>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="gap-2 flex items-center">
            <User />
            <div>
              <p className="font-normal text-gray-500 font-poppins text-sm">
                {" "}
                Body 1
              </p>
            </div>
          </div>
          <div>
            <Link
              to="#"
              className="font-normal text-themeBlue underline font-poppins text-sm"
            >
              Open Ticket
           </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-end my-2">
        <ShowLimit
          paginationCurrentLimit={paginationCurrentLimit}
          setPaginationCurrentLimit={setPaginationCurrentLimit}
        />
        <p className="text-xs">
          {(data.current_page - 1) * data.per_page + 1} -{" "}
          {Math.min(data.current_page * data.per_page, data.total)} of{" "}
          {data.total}
        </p>
        <div className="px-4 py-2 rounded-lg">
          <Pagination
            total={data.total}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={paginationCurrentLimit.value}
          />
        </div>
      </div>
      {replyTicketModel && (
        <ReplyTicket
          setReplyTicketModel={setReplyTicketModel}
          replyTicketModel={replyTicketModel}
          isOpen={isOpen}
        />
      )}
    </div>
  );
};
export default AllTickets;
